export class ApiConfig {

    static getApiBaseUrl() {
        // return 'http://localhost:8080/api/v1';
        return 'https://skyaccuracy.cz/api/v1';
    }

    // TODO consider this :https://stackoverflow.com/questions/51133052/how-to-add-a-url-config-file-for-vue-project

    static sunRealtimeUrl() {
        return `${(ApiConfig.getApiBaseUrl())}/sun/realtime`;
    }

    static sunAnimationUrl() {
        return `${(ApiConfig.getApiBaseUrl())}/sun/animation`;
    }

    // eslint-disable-next-line no-unused-vars
    static verificationPageURL(counties) {
        let regionalParam = "";
        if (counties && counties.length > 0) {
            regionalParam = "?locations=" + counties.join(",");
        }
        return `${(ApiConfig.getApiBaseUrl())}/verification${regionalParam}`;
    }

    static verifiedForecastsPageURL(locations, daysOffsetFromToday) {
        let params = "?daysOffsetFromToday=" + daysOffsetFromToday;
        if (locations && locations.length > 0) {
            params = params + "&locations=" + locations.join(",");
        }
        return `${(ApiConfig.getApiBaseUrl())}/verified-forecast${params}`;
    }

    static autocompletedLocationsURL(searchText, countryCode) {
        let params = "?text=" + searchText;
        if (countryCode) {
            params = params + "&countryCode=" + countryCode;
        }
        return `${(ApiConfig.getApiBaseUrl())}/location/autocomplete${params}`;
    }

    static forecastsPageURL(partialUpdate, locationName, lat, lon, countryCode, firstAccess, reliabilityRadius,
                            days, nightReliabilityDays, displayedSources,
                            nightFromHour, nightToHour, reliabilityType, detailSourceMode, preferredDetailSource) {
        let params = "?dummy=dummy";
        const radiusTypes = ['RADIUS_WHOLE_COUNTRY', 'RADIUS_50', 'RADIUS_100', 'RADIUS_150', 'RADIUS_200'].join(",");
        const displayedSourcesParam = ApiConfig.getDisplayedSourcesParamVal(displayedSources);
        countryCode = countryCode ? countryCode : "cz";
        if (locationName && lat && lon) {
            params =  params + `&location=${locationName}&lat=${lat}&lon=${lon}&countryCode=${countryCode}`;
        }
        if (reliabilityRadius) {
            params = params + `&reliabilityRadius=${reliabilityRadius}`;
        }
        if (firstAccess) {
            params = params + `&firstAccess=${firstAccess}`;
        }

        params = params + `&days=${days}&radiusTypes=${radiusTypes}&nightReliabilityDays=${nightReliabilityDays}`;

        if (nightFromHour !== null && nightToHour != null) {
            params = params + `&nightFromHour=${nightFromHour}&nightToHour=${nightToHour}`;
        }
        if (reliabilityType !== null) {
            params = params + `&reliabilityType=${reliabilityType}`;
        }
        if (detailSourceMode !== null) {
            params = params + `&detailSourceMode=${detailSourceMode}`;
        }
        if (preferredDetailSource !== null) {
            params = params + `&preferredSourceOrder=${preferredDetailSource}`;
        }
        params = params + `&${displayedSourcesParam}`;

        let path;
        if (partialUpdate) {
            path = 'forecast/partial';
        } else {
            path = 'forecast';
        }
        return `${(ApiConfig.getApiBaseUrl())}/${path}${params}`;
    }

    static forecastsPageByUuidURL(uuid, firstAccess, reliabilityRadius,
                                  dayCount, nightReliabilityDays, displayedSources,
                                  nightFromHour, nightToHour, reliabilityType, detailSourceMode, preferredDetailSource) {
        let params;
        const radiusTypes = ['RADIUS_WHOLE_COUNTRY', 'RADIUS_50', 'RADIUS_100', 'RADIUS_150', 'RADIUS_200'].join(",");
        const displayedSourcesParam = ApiConfig.getDisplayedSourcesParamVal(displayedSources);
        params = `?uuid=${uuid}&dayCount=${dayCount}&firstAccess=${firstAccess}&${displayedSourcesParam}&radiusTypes=${radiusTypes}&nightReliabilityDays=${nightReliabilityDays}`;
        if (reliabilityRadius) {
            params = params + `&reliabilityRadius=${reliabilityRadius}`;
        }
        if (nightFromHour !== null && nightToHour != null) {
            params = params + `&nightFromHour=${nightFromHour}&nightToHour=${nightToHour}`;
        }
        if (reliabilityType !== null) {
            params = params + `&reliabilityType=${reliabilityType}`;
        }
        if (detailSourceMode !== null) {
            params = params + `&detailSourceMode=${detailSourceMode}`;
        }
        if (preferredDetailSource !== null) {
            params = params + `&preferredSourceOrder=${preferredDetailSource}`;
        }
        return `${(ApiConfig.getApiBaseUrl())}/forecast${params}`;
    }

    static getDisplayedSourcesParamVal(displayedSources) {
        if (displayedSources && displayedSources.length > 0) {
            return "displayedSources=" + displayedSources.join(",");
        } else {
            return "";
        }
    }

    static forecastsLinkURL(locationName, lat, lon, countryCode, firstAccess, displayedDay) {
        let params;
        const radiusTypes = ['RADIUS_WHOLE_COUNTRY', 'RADIUS_50', 'RADIUS_100', 'RADIUS_150', 'RADIUS_200'].join(",");
        if (locationName && lat && lon && countryCode) {
            params = `?location=${locationName}&lat=${lat}&lon=${lon}&countryCode=${countryCode}&displayedDay=${displayedDay}&firstAccess=${firstAccess}&radiusTypes=${radiusTypes}`;
        } else {
            params = `?days=${displayedDay}&radiusTypes=${radiusTypes}`;
        }
        return `${(ApiConfig.getApiBaseUrl())}/forecast/link${params}`;
    }

    static forecastsCommentaryURL(uuid) {
        let params;
        if (uuid) {
            params = `?uuid=${uuid}`;
        } else {
            return null;
        }
        return `${(ApiConfig.getApiBaseUrl())}/forecast/commentary${params}`;
    }

    static forecastsCommentaryStreamedURL(uuid) {
        let params;
        if (uuid) {
            params = `?uuid=${uuid}`;
        } else {
            return null;
        }
        return `${(ApiConfig.getApiBaseUrl())}/forecast/commentary/stream${params}`;
    }


    static saveHomeLocationLink(locationName, lat, lon, countryCode, firstAccess) {
        let params = "";
        if (locationName && lat && lon && countryCode) {
            params = `?location=${locationName}&lat=${lat}&lon=${lon}&countryCode=${countryCode}&firstAccess=${firstAccess}`;
        }
        return `${(ApiConfig.getApiBaseUrl())}/forecast/home/location${params}`;
    }

    static deleteHomeLocationLink(firstAccess) {
        let params = "";
        if (firstAccess) {
            params = `?firstAccess=${firstAccess}`;
        }
        return `${(ApiConfig.getApiBaseUrl())}/forecast/home/location${params}`;
    }

    static forecastReliabilitiesURL(locationName, lat, lon, countryCode, reliabilityRadius, reliabilityType) {
        let params;
        const radiusTypes = ['RADIUS_WHOLE_COUNTRY', 'RADIUS_50', 'RADIUS_100', 'RADIUS_150', 'RADIUS_200'].join(",");
        if (locationName && lat && lon) {
            params = `?location=${locationName}&lat=${lat}&lon=${lon}&countryCode=${countryCode}&radiusTypes=${radiusTypes}`;
            if (reliabilityRadius) {
                params = params + `&radius=${reliabilityRadius}`;
            }
        } else {
            params = `?radiusTypes=${radiusTypes}`;
        }
        if (reliabilityType !== null) {
            params = params + `&reliabilityType=${reliabilityType}`;
        }
        return `${(ApiConfig.getApiBaseUrl())}/forecast/reliabilities${params}`;
    }

    static forecastNightReliabilitiesURL(locationName, lat, lon, reliabilityRadius, nightReliabilityDays) {
        let params;
        if (locationName && lat && lon) {
            params = `?location=${locationName}&lat=${lat}&lon=${lon}&nightReliabilityDays=${nightReliabilityDays}`;
            if (nightReliabilityDays) {
                params = params + `&nightReliabilityDays=${nightReliabilityDays}`;
            }
            if (reliabilityRadius) {
                params = params + `&radius=${reliabilityRadius}`;
            }
        } else {
            params = `?nightReliabilityDays=${nightReliabilityDays}`;
        }
        return `${(ApiConfig.getApiBaseUrl())}/forecast/reliabilities/charts/night${params}`;
    }

    static accessURL(timezone, firstAccess) {
        let params;
        if (timezone) {
            params = `?timezone=${timezone}&firstAccess=${firstAccess}`;
        } else {
            params = "?firstAccess=${firstAccess}";
        }
        return `${(ApiConfig.getApiBaseUrl())}/access${params}`;
    }

}
