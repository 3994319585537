<template>

    <div class="container page-container">

        <h2 style="padding-top: 30px;text-align: center">Aktuality na SkyAccuracy.cz</h2>
        <hr>

        <h6>2025-02-18, změny v sekci Předpovědí:</h6>
        <ul>
            <li>Zásadní přepracování prezentace ukazatelů povětrnostních podmínek (rychlost větru, teplota, srážky ...).
                Tabulkové zobrazení doplněno grafy, které lépe zobrazují vývoj podmínek v čase. Každý graf je možné rozkliknout
                pro zobrazení detailu jednotlivých modelů.
            </li>
            <li>Pro nastavení zobrazení ukazatelů navíc přibylo v menu nastavení "Povětrnostní podmínky". Tam lze
            vybrat z několika možností, jak a jaká data primárně zobrazovat.</li>
        </ul>

        <h6>2024-10-21, změny v sekci Předpovědí:</h6>
        <ul>
            <li>Přidány výstrahy před nepřízní počasí jako je nízká teplota pro dané období v roce, silný vítr, výskyt mlhy, vysoká vlhkost
                a znečištěné ovzduší. Brzy přidáme i hloubku sněhu.</li>
            <li>Nově možnost zobrazit předpovědi oblačnosti v podobě ikon</li>
            <li>Přidán graf úspěšnosti pesimistických modelů proti většímu počtu optimistických modelů</li>
            <li>Srovnávání hodinových předpovědí a měření ve více lokalitách a také s rovnoměrnějším pokrytím území ČR.
            Díky tomu mají všechny typy indikátorů přesnosti jednotlivých modelů větší vypovídající hodnotu.</li>
        </ul>

        <h6>2024-08-23, změny v sekci Předpovědí:</h6>
        <ul>
            <li>Přidána možnost nastavit vyhledanou polohu jako domovskou.
                Volba je dostupná v kontextovém nastavení předpovědí a je platná pro použité zařízení</li>
        </ul>

        <h6>2024-08-18, změny v sekci Předpovědí:</h6>
        <ul>
            <li>Nové popup menu s užitečnými odkazy na jiné weby o počasí</li>
            <li>Nový graf ukazující spolehlivost předpovědí oblačné oblohy pro X posledních nocí</li>
            <li>Ikona Slunce nyní ukazuje aktuální sluneční skvrny</li>
            <li>Legenda pro seeing a průzračnost vylepšena o číselné vyjádření stupnice spolu se slovním popisem</li>
        </ul>

        <h6>2024-08-10, změny v sekci Předpovědí:</h6>
        <ul>
            <li>Přidána animace stmívající se oblohy</li>
        </ul>

        <h6>2024-08-01, změny v sekci Předpovědí:</h6>
        <ul>
            <li>Zrychlení načítání úvodní stránky s předpovědí pro Českou republiku</li>
        </ul>

<!--        for now to keep the footer down-->
        <div style="height: 500px"></div>

    </div>

</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: "Changelog",
    mounted () {
        window.scrollTo(0, 0)
    },
    data() {
        return {
            showTooltip: false // workaround for clicked locations to lose focus and the tooltip to be hidden
        }
    },
    computed: {
        ...mapGetters([])
    },
    methods: {
    }
}

</script>

<style scoped>
.small-header {
    padding-top: 10px;
    text-align: center
}

.source {
    font-weight: 600;
}

.usage {
    font-weight: 500;
}

/*.tooltip {*/
/*    position: absolute;*/
/*    background-color: black;*/
/*    color: white;*/
/*    padding: 5px;*/
/*    border-radius: 5px;*/
/*    opacity: 0;*/
/*    transition: opacity 0.3s ease-in-out;*/
/*}*/

h6 {
    margin-top: 30px;
}

</style>
