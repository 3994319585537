import {Utils} from "@/utils/Utils";

export let utilsMixin = {
    methods: {
        padded: function (value) {
            return String(value).padStart(4, ' ');
        },

        formatNumberWithSign: function(number) {
            return number > 0 ? `+${number}` : number.toString();
        },

        formatCurrency: function (amount) {
            return Utils.formatCurrency(amount);
        },

        formatPercentage(value) {
            return Utils.formatPercentage(value);
        },

        formatPercentageTimes100(value) {
            return Utils.formatPercentage(Utils.times(value, 100));
        },

        monthsToCZText: function (value) {
            return Utils.monthsToCZText(value);
        },

        monthsToCZYearsMonthsText: function (value) {
            return Utils.monthsToCZYearsMonthsText(value);
        },

        yearsToCZText: function (value) {
            return Utils.yearsToCZText(value);
        },

        div: function (num, divisor) {
            return Utils.div(num, divisor);
        },

        times: function (num, multiplier) {
            return Utils.times(num, multiplier);
        }

    }

}
