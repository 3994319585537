<template>
    <div>
        <div class="bg-white " style="overflow: auto;">
            <LineChart v-if="loaded" :chartData="datacollection" :chartOptions="options"></LineChart>
        </div>
    </div>
</template>

<script>

// Video on  https://www.youtube.com/watch?v=N8-p9bdYWqs&ab_channel=ChartJS

import LineChart from "@/components/reusables/LineChart.vue";
import {mapActions, mapGetters} from "vuex";
import {Sources} from "@/config/sources";
// import {Utils} from "@/utils/Utils";

export default {
    name: "LongTermVerificationsChart",
    computed: {
        ...mapGetters('verificationsStore', ['getDailyValues', 'getDailyChart', 'getDisplayedDailyChartDiffType']),
        ...mapGetters('settingsStore', ['getDisplayedSources']),
    },
    watch: {
        getDailyChart: function () {
            this.refreshData();
        },
        getDisplayedDailyChartDiffType: function () {
            this.refreshData();
        }
    },
    data() {
        return {
            loaded: false,
            selected: 'diff',
            datacollection: null,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'bottom',
                    labels : {
                        usePointStyle: true,
                        padding: 20
                    }
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                scales: {
                    xAxes: [{
                        display: true,
                        type: 'time',
                        time: {
                            unit: 'day',
                            displayFormats: {
                                day: 'D.M',
                            },
                        },
                        gridLines: {
                            zeroLineWidth:1.5,
                            zeroLineColor: 'rgb(0,0,0)'
                        },
                        ticks: {
                            fontColor: 'rgb(0,0,0)'
                        },
                    }],
                    yAxes: [{
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Odchylka %',
                            padding: 0
                        },
                        beginAtZero: true,
                        ticks: {
                            // suggestedMin: -30, // TODO change based on data ...
                            // suggestedMax: 30,
                            fontSize: 11,
                            fontColor: 'rgb(77,77,77)',
                            maxTicksLimit: 7,
                            padding: 4
                        },
                        gridLines: {
                            zeroLineWidth:1.5,
                            zeroLineColor: 'rgb(0,0,0)'
                        },
                    }]
                },
                tooltips: {
                    mode: 'index',     // Show the tooltip for the nearest point
                    intersect: window.innerWidth < 1000,    // Show tooltip even if not directly on the point on larger screens only
                    backgroundColor: 'rgba(255, 255, 255, 1)',  // Fully transparent background
                    titleFontColor: 'black',                    // Black font for title
                    bodyFontColor: 'black',                     // Black font for body
                    callbacks: {
                        label: function(tooltipItem, data) {
                            // Custom label format, for example, showing y-axis value
                            const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y;
                            const label = data.datasets[tooltipItem.datasetIndex].label;
                            const paddedValue = `${value}%`.padStart(4, ' ');
                            return `${paddedValue}  ${label}` ;
                        }
                    },
                },
                plugins: { // global options for chart ...
                    datalabels: {
                        font: {
                            size: 9,
                            // weight: 700
                        },
                        color: 'rgba(7,14,14,0.79)',
                        anchor: 'center',
                        align: 'left',
                        offset: 6,
                        // eslint-disable-next-line no-unused-vars
                        display: function (context) {
                            // return (context.dataIndex === context.dataset.data.length - 1);
                            return true;
                        },
                        // eslint-disable-next-line no-unused-vars
                        formatter: function (value, context) {
                            return "";
                        }
                    }
                },
                elements: {
                    point: {
                        radius: 1.5,
                        hoverRadius: 6
                    }
                }
            }
        }
    },
    methods: {
        ...mapActions(['toggleDisplayedDailyChartDiffType']),
        refreshData() {
            this.datacollection = {};

            const sourceInputs = [
                {source: 'ACCUWEATHER_WEB', borderColor: 'rgb(239,153,93)'},
                {source: 'ALADIN_API', borderColor: 'rgba(241,221,119,0.93)'},
                {source: 'METEOSOURCE_API', borderColor: 'rgb(166,164,163)'},
                {source: 'OPEN_METEO_ECMWF', borderColor: 'rgb(255,112,124)'},
                {source: 'OPEN_METEO_GFS', borderColor: 'rgb(163,236,236)'},
                {source: 'OPEN_METEO_ICON', borderColor: 'rgb(221,181,255)'},
                // {source: 'METEOBLUE_API', borderColor: 'rgb(67,156,229)'},
                {source: 'METEOBLUE_WEB', borderColor: 'rgb(147,199,241)'},
                {source: 'MET_NORWAY_API', borderColor: 'rgb(137,211,156)'}
            ]

            this.datacollection.datasets = sourceInputs.filter(i => this.getDisplayedSources.includes(i.source)).map(i => {
                return {
                    label: Sources.displayName(i.source),
                    backgroundColor: 'rgba(255,255,255,0)',
                    borderColor: i.borderColor,
                    borderWidth: 1.5,
                    data: this.getDailyValues(i.source)
                }
            })

            this.loaded = true;
        }
    },
    mounted() {
        console.log("Mounting DailyChart component ... ");
        this.refreshData();
    },
    components: {
        LineChart
    }
}
</script>

<style>
</style>
