<template>
    <div>

        <ForecastDays></ForecastDays>

        <ForecastSunMoon></ForecastSunMoon>

        <ForecastStatuses></ForecastStatuses>

        <h5 style="margin-bottom: 10px; text-align: center; font-size: 22px; font-weight: 600">
            {{getCloudTypeTitle()}}
        </h5>

        <ForecastCommentary></ForecastCommentary>

        <ForecastCloudOptions></ForecastCloudOptions>

        <div ref="forecastTableContainer" class="table-responsive" style="overflow-x: scroll; margin-right: 10px">
            <table style="background-color: white" class="forecast-table">
                <thead>
                    <tr class="forecast-row forecast-row-shadow" :class="getTableCenteringClass">
                        <th class="forecast-row-header cell"
                                style="background-color: #8c8c8c; color: white; font-weight: bold; text-align: center; min-width: 110px; font-size: 11px">
                            Model \ Hodina
                        </th>
                        <th v-for="hour in hours" :key="hour" class="cell" :style="currentHourStyle(hour)"
                            style="background-color: #413f3f; color: white; font-weight: normal; min-width: 25px;"
                            v-bind:ref="'hr-' + hour">
                            {{ hour }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="sourceCode in getDisplayedSourcesIfDataExists()" v-bind:key="sourceCode"
                        class="forecast-row" :class="getTableCenteringClass">
                        <td nowrap class="forecast-row-header cell">
<!--                            TODO do we really need to dupicate the following lines because of the check for forecast?-->
                            <div v-if="getSourceForecast(sourceCode, 0)">
                                <div class="forecast-row-name source-name-parent d-flex align-items-end align-content-end">
                                    <span style="margin-right: auto;" v-bind:style="getSourceStyle(sourceCode)" class="notranslate">
                                        {{ getSourceForecast(sourceCode, 0).sourceTitle }}
                                    </span>
                                    <span v-if="getReliabilities" class="reliability" :style="getAccuracyStyle(sourceCode)">
                                        {{ getAccuracySign(sourceCode) }}
                                    </span>
                                </div>
                            </div>
                            <div v-else class="forecast-row-name source-name-parent d-flex align-items-end align-content-end">
                                <span style="margin-right: auto">{{ sourceDisplayName(sourceCode) }}</span>
                                <span v-if="getReliabilities" class="reliability" :style="getAccuracyStyle(sourceCode)">
                                    {{ getAccuracySign(sourceCode) }}
                                </span>
                            </div>
                        </td>
                        <td v-for="hour in hours" :key="hour" class="cell cloud-cell" v-bind:style="getForecastStyle(hour, getHourForecast(hour, getSourceForecast(sourceCode, hour)))">
                            <div v-if="getClouds(hour, getSourceForecast(sourceCode, hour)) !== null" class="cell-value">
                                {{ getClouds(hour, getSourceForecast(sourceCode, hour)) }}
                            </div>
                            <div v-else-if="getCloudIconNo(hour, getSourceForecast(sourceCode, hour)) !== null" class="cell-icon">
                                <img style="width: 22px; height: 23px"  alt="Clouds" :src="require('@/assets/icons/meteoblue/weather_pictograms/svg/'
                                    + getCloudIconNo(hour, getSourceForecast(sourceCode, hour)) + '.svg')"
                                />
                            </div>
                            <div v-else class="no-data">
                                -
                            </div>
                        </td>
                    </tr>
                    <tr v-if="getForecastAvgMode !== 'NONE' && getDisplayedCloudType !== 'total_icon'"
                        style="border-top: 2px solid #262626" class="forecast-row" :class="getTableCenteringClass">
                        <td class="forecast-row-header cell">
                            <div class="forecast-row-name source-name-parent d-flex align-items-end align-content-end">
                                Ø {{ getForecastAvgModeName() }}
                            </div>
                        </td>
                        <td v-for="hour in hours" :key="hour" v-bind:style="getForecastAvgStyle(hour)" class="cell cloud-cell" style="font-weight: bolder">
                            <div v-if="getCloudsAvg(hour) !== null" class="cell-value">
                                {{ getCloudsAvg(hour) }}
                            </div>
                            <div v-else class="no-data">
                                -
                            </div>
                        </td>
                    </tr>

                    <tr style="height: 20px" class="forecast-row forecast-row-shadow"></tr>

                    <tr style="height: 28px" class="forecast-row forecast-row-shadow" :class="getTableCenteringClass">
                        <td class="forecast-row-header cell">
                            <div class="forecast-row-name d-flex align-items-end align-content-end">
                                Den a noc
                            </div>
                        </td>
<!--                        TODO all this move to <DayNightHourCell and rename-->
                        <DayNightHourCell v-for="hour in hours" :id="'sun-' + hour" :key="hour" :hour="hour"></DayNightHourCell>
                    </tr>

                    <ForecastTableDetailRow v-for="detailField in getDetailFields" :key="detailField" :detail-field="detailField"
                                            @click="detailRowClicked(detailField)">
                    </ForecastTableDetailRow>

                </tbody>
            </table>
        </div>

        <div v-if="isDisplayedWholeCountry" style="font-style: italic; margin-top: 5px; font-size: 13px">
            * Aktuálně zobrazované hodnoty jsou průměrem za celou ČR.
        </div>

    </div>


</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {utilsMixin} from "@/components/reusables/utils-mixin";
import {BFormRadio, VBToggle} from 'bootstrap-vue';
import Vue from "vue";
import {ForecastColors} from "@/config/forecast-colors";
import {Sources} from "@/config/sources";
import ForecastCloudOptions from "@/components/forecast/ForecastCloudOptions";
import ForecastDays from "@/components/forecast/ForecastDays";
import ForecastSunMoon from "@/components/forecast/ForecastSunMoon";
import {Utils} from "@/utils/Utils";
import ForecastStatuses from "@/components/forecast/ForecastStatuses.vue";
import ForecastTableDetailRow from "@/components/forecast/ForecastTableDetailRow.vue";
import DayNightHourCell from "@/components/forecast/DayNightHourCell.vue";
import ForecastCommentary from "@/components/forecast/ForecastCommentary.vue";
// Note: Vue automatically prefixes the directive name with 'v-'
Vue.directive('b-toggle', VBToggle)


export default {
    name: 'ForecastTable',
    mixins: [utilsMixin],
    props: {
        uuid: null
    },
    mounted() {
        console.log("Mounting ForecastTable component ... ");
        if (this.uuid) {
            this.loadForecastsForUuid(this.uuid);
        } else {
            if (!this.getForecasts) {
                this.loadForecastsForWholeCountry();
            } else {
                this.scrollTable();
            }
        }
    },
    beforeDestroy() {
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        getForecasts: function (newValue) {
            // console.log("Inside watch for getForecasts ...")
            // we need a small delay fails to scroll when data is first loaded as it probably is not finished rendering
            setTimeout(() => this.scrollTable(), 20);
        },
    },
    data() {
        return {
            minDiffForAccuracyStyling: 5,
        }
    },
    computed: {
        Utils() {
            return Utils
        },
        ...mapGetters('forecastsStore', [
            'getSourceForecasts', 'getForecastDate', 'shouldCenterOnMidnight',
            'todayForecastDisplayed', 'nextDayExists', 'getDayMovement',
            'getSourceForecast', 'getForecasts', 'getDetailedForecast',
            'existsSourceForecast', 'getDisplayedCloudType',
            'getDyHourIntervals', 'showMoon',
            'getReliabilities', 'getSourceReliability',
            'getSourceCodesByReliabilityDesc', 'getForecastAvgMode', 'isDisplayedWholeCountry',
            'getSmartSourcesToDisplay', 'isDaytimeHour',
            'getHourForecast', 'hours'
        ]),
        ...mapGetters('forecastDetailsStore',
            ['getDetailFields']),
        ...mapGetters('forecastStylesStore',
            ['getTableCenteringClass', 'currentHourStyle']),
        ...mapGetters('settingsStore',
            ['getDisplayedSources']),
    },
    methods: {
        ...mapActions('forecastsStore', ['loadForecastsForWholeCountry', 'loadForecastsForUuid']),
        scrollTable() {
            // console.log("Inside scrollTable() ...");
            // insure we are leftmost -> so that we can then scroll to midnight ...
            this.$refs.forecastTableContainer.scrollBy({
                left: -2000,
            });

            const hour = this.shouldCenterOnMidnight ? 21 : 9;
            const targetHourEl = this.$refs['hr-' + hour][0];
            const targetLeft = targetHourEl.getBoundingClientRect().left;
            const width = document.documentElement.scrollWidth;
            // console.log("targetLeft = " + targetLeft + "; width = " + width);
            if (targetLeft + 200 > width) {
                const scrollBy = targetLeft - width + 200;
                // console.log("scrollBy = " + scrollBy)
                this.$refs.forecastTableContainer.scrollBy({
                    left: scrollBy,
                    behavior: 'smooth'
                });
            } else {
                // console.log("No scrolling ...")
            }
        },
        getDisplayedSourcesIfDataExists() {
            return this.getDisplayedSources.filter(s => this.existsSourceForecast(s, this.hours));
        },
        sourceDisplayName(sourceCode) {
            return Sources.displayName(sourceCode);
        },
        getCloudValue(hourForecast) {
            const type = this.getDisplayedCloudType;
            if (type === 'high') {
                return hourForecast.cloudHigh;
            } else if (type === 'middle') {
                return hourForecast.cloudMiddle;
            } else if (type === 'low') {
                return hourForecast.cloudLow;
            } else if (type === 'total') {
                return hourForecast.cloudTotal;
            } else {
                return null;
            }
        },
        getForecastStyle(hour, hourForecast) {
            // TODO based on mode ...
            if (hourForecast) {
                const type = this.getDisplayedCloudType;
                if (type === 'total_icon') {
                    // return "background-color: rgb(101 172 244);" + midnightBorderStyle;
                    let backgroundColor = "rgba(100,162,218,0.87)";
                    return `background-color: ${backgroundColor}`;
                } else {
                    return this.getCloudValueStyle(this.getCloudValue(hourForecast));
                }
            }
            return '';
        },
        getSourceStyle(sourceCode) {
            let sourcesToDisplay = this.getSmartSourcesToDisplay;
            if (sourcesToDisplay) {
                if (Utils.isMe() && sourcesToDisplay.includes(sourceCode)) {
                    return "color: green";
                }
            }
            return "";
        },
        getForecastAvgStyle(hour) {
            const cloudsAvg = this.getCloudsAvg(hour);
            if (cloudsAvg || cloudsAvg === 0) {
                return this.getCloudValueStyle(cloudsAvg);
            }
            return '';
        },
        getCloudValueStyle(cloud) {
            if (cloud || cloud === 0) {
                const clouds = cloud === 100 ? 99 : cloud;
                let background = ForecastColors.shadesOfBlue[Math.floor(clouds / 10)];
                return "backgroundColor: " + background
            }
            return "";
        },
        getClouds(hour, forecast) {
            if (forecast) {
                let hourForecast = this.getHourForecast(hour, forecast);
                if (hourForecast) {
                    const value = this.getCloudValue(hourForecast);
                    if (value !== null && value !== undefined) {
                        return value
                    }
                }
            }
            return null;
        },
        getCloudIconNo(hour, forecast) {
            const type = this.getDisplayedCloudType;
            if (forecast && type === 'total_icon') {
                let hourForecast = this.getHourForecast(hour, forecast);
                if (hourForecast) {
                    let suffix = '_night';
                    if (this.isDaytimeHour(hour)) {
                        suffix = '_day';
                    }
                    return hourForecast.iconNo + suffix;
                }
            }
            return null;
        },
        getCloudsAvg(hour) {
            let count = 0;
            let sum = 0;

            const limit = this.getForecastAvgMode === 'ALL' ? this.getDisplayedSources.length
                : this.getForecastAvgMode === 'BEST_3' ? 3
                    : this.getForecastAvgMode === 'BEST_4' ? 4
                        : this.getForecastAvgMode === 'BEST_5' ? 5
                            : 0;

            const codes = this.getForecastAvgMode === 'ALL' ? this.getDisplayedSources
                : this.getForecastAvgMode === 'BEST_3' ? this.getSourceCodesByReliabilityDesc
                    : this.getForecastAvgMode === 'BEST_4' ? this.getSourceCodesByReliabilityDesc
                        : this.getForecastAvgMode === 'BEST_5' ? this.getSourceCodesByReliabilityDesc
                            : [];

            let checkedCount = 0;

            for (const sourceCode of codes) {
                if (this.getDisplayedSources.includes(sourceCode)) {
                    checkedCount++;
                    const forecast = this.getSourceForecast(sourceCode, hour);
                    const clouds = this.getClouds(hour, forecast);
                    if (clouds || clouds === 0) {
                        count++;
                        sum += clouds;
                    }
                    if (checkedCount === limit) {
                        break;
                    }
                }
            }
            if (count > 0) {
                return Math.floor(sum / count);
            }
            return null;
        },
        getForecastAvgModeName() {
            if (this.getForecastAvgMode === 'ALL') {
                return ' všech';
            }
            const included = this.getForecastAvgMode === 'BEST_3' ? 3
                : this.getForecastAvgMode === 'BEST_4' ? 4
                    : this.getForecastAvgMode === 'BEST_5' ? 5 : 0;

            if (included > 0) {
                return " " + included + " nejpřes.";
            }
            return "";
        },
        getAccuracyStyle(sourceCode) {
            let background;
            let fontStyle = "";
            const sourceReliability = this.getSourceReliability(sourceCode);
            if (sourceReliability
                && sourceReliability.reliability >= 0) {
                const idx = Math.floor(sourceReliability.reliability / 10.001); // divide like this to get index between 0 - 9 always
                background = ForecastColors.shadesOfReliability[idx];
                fontStyle = "; font-weight: 600 ;color: " + ForecastColors.shadesOfReliabilitySign[idx];
            } else {
                background = ForecastColors.noComparisonColor;
            }
            const style = "background-color: " + background + fontStyle;
            return style;
        },
        getAccuracySign(sourceCode) {
            const sourceReliability = this.getSourceReliability(sourceCode);
            // TODO source the threshold from BE
            if (sourceReliability && Math.abs(sourceReliability.avgDiff) >= 10) {
                if (sourceReliability.avgDiff > 0) {
                    // more clouds then forecast
                    return "+";
                } else {
                    return "-";
                }
            }
            return "";
        },
        getCloudTypeTitle() {
            if (this.getDisplayedCloudType === 'total_icon') {
                return "Oblačnost";
            } else {
                return "Oblačnost v %";
            }
        },
    },
    components: {
        ForecastCommentary,
        DayNightHourCell,
        ForecastTableDetailRow,
        // eslint-disable-next-line vue/no-unused-components
        BFormRadio,
        ForecastStatuses,
        ForecastCloudOptions,
        ForecastDays,
        ForecastSunMoon,
    }
}
</script>



<style scoped>

table {
    border-collapse: collapse;
    overflow-x: auto; /* enable horizontal scrolling */
    /*TODO with this enabled the programtic scrolling does not work*/
    /*display: block; !* important for sticky positioning *!*/
    user-select: none;
}

</style>

