<template>
    <span class="dot-spinner">
        <span class="dot-span" v-for="n in 3" :key="n"></span>
    </span>
</template>

<script>
export default {
    name: 'DotSpinner'
}
</script>

<style scoped>
.dot-spinner {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.dot-span {
    width: 5px;
    height: 5px;
    margin: 0 2px;
    background-color: rgba(150, 150, 150, 0.78);
    border-radius: 50%;
    animation: dot-blink 1.4s infinite both;
}

.dot-span:nth-child(1) {
    animation-delay: -0.32s;
}

.dot-span:nth-child(2) {
    animation-delay: -0.16s;
}

@keyframes dot-blink {
    0%, 80%, 100% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
}
</style>
