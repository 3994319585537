<template>
    <span style="z-index: 100">
        <b-icon-info-circle v-if="infoIcon"
                :id=targetId
                size="sm"
                variant="info"
                aria-describedby="tooltip"
        />
        <b-tooltip v-bind:target=targetId :id=tooltipId :placement=placement>
            <div class="tooltip-content">
                {{text}}
            </div>
        </b-tooltip>
    </span>

</template>

<script>
import {utilsMixin} from "@/components/reusables/utils-mixin";
import {BIconInfoCircle} from 'bootstrap-vue';
import {BTooltip} from 'bootstrap-vue';


export default {
    name: 'Tooltip',
    mixins: [utilsMixin],
    props: {
        id: String,
        text: String,
        infoIcon: {
            type: Boolean,
            default: true
        },
        placement: {
            type: String,
            default: "left"
        }
    },
    data() {
        return {
            randomId: this.generateRndId()
        }
    },
    computed: {
        tooltipId: function () {
            return "tooltip-" + this.getId();
        },
        targetId: function () {
            return "" + this.getId(); // must be string
        }
    },
    methods: {
        getId() {
            if (this.id) {
                return this.id;
            }
            return this.randomId;
        },
        generateRndId() {
            return Math.random() * 1000000000;
        }
    },
    components: {BIconInfoCircle, BTooltip}
}
</script>


<style scoped>
.tooltip-content {
    user-select: none; /* Prevents text selection */
    pointer-events: none; /* Prevents focus and click events */
}
</style>
