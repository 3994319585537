import {Sources} from "@/config/sources";

export class CHART_HEIGHT {
    static SMALL = {
        name: "SMALL",
        label: "Malá",
        height: 30, // chart bar
        rowHeight: 42,
    }
    static MEDIUM = {
        name: "MEDIUM",
        label: "Střední",
        height: 38, // chart bar
        rowHeight: 50,
    }
    static LARGE = {
        name: "LARGE",
        label: "Velká",
        height: 55, // chart bar
        rowHeight: 67,
    }

    static OPTIONS = [CHART_HEIGHT.SMALL, CHART_HEIGHT.MEDIUM, CHART_HEIGHT.LARGE]

    static findOption(name) {
        return CHART_HEIGHT.OPTIONS.find(v => v.name === name)
    }

}

const state = {
    details: new Map([
        ['skyQuality-title', {display: 'Kvalita oblohy', divider: true, icon: "stars_icon.png"}],
        ['skyQuality-hours', {hoursRow: true}],
        ['seeing', {display: 'Seeing (arcsec)', divider: false,
            firstInSection: true}
        ],
        ['transparency', {display: 'Průzračnost (mag/air mass)', divider: false, wrappedHeader: true}],
        ['visibility', {display: 'Viditelnost (km)', divider: false, highlight: true,
            warningMax: () => 10, criticalMax: () => 6, greatMin: () => 30}
        ],
        ['fog', {display: 'Mlha (%)', divider: false, highlight: true,
            warningMin: () => 25, criticalMin: () => 30}
        ],
        ['aerosol550', {display: 'Op. hl. aerosolů', divider: false, highlight: true,
            warningMin: () => 0.15, criticalMin: () => 0.5, greatMax: () => 0.1}
        ],
        ['dust550', {display: ' ... z toho prach', divider: false, highlight: true,
            warningMin: () => 0.05, criticalMin: () => 0.1, greatMax: () => 0.001,
            lastInSection: true}
        ],

        ['wind-title', {display: 'Vítr', divider: true, icon: "wind_icon.png"}],
        ['wind-hours', {hoursRow: true}],
        ['windAngle', {display: 'Směr větru', divider: false,
            firstInSection: true}
        ],
        ['windSpeed', {display: 'Vítr (m/s)', divider: false, highlight: true,
            warningMin: () => 3, criticalMin: () => 5, greatMax: () => 1.0}
        ],
        ['windGusts', {display: 'Poryvy (m/s)', divider: false, highlight: true,
            warningMin: () => 5, criticalMin: () => 10, greatMax: () => 1.5,
            lastInSection: true}
        ],

        ['temperature-title', {display: 'Teplota', divider: true, icon: "temperature_icon.png"}],
        ['temperature-hours', {hoursRow: true}],
        // TODO this should follow same rules are wanings in the backend
        ['temperature', {display: 'Teplota (°C)', divider: false, highlight: true,
            warningMax: () => getWarningMaxTemperature(), criticalMax: () => getCriticalMaxTemperature(),
            firstInSection: true}
        ],
        ['feelsLike', {display: 'Pocitová (°C)', divider: false, highlight: true,
            warningMax: () => getWarningMaxTemperature(), criticalMax: () => getCriticalMaxTemperature()}],
        ['dewPoint', {display: 'Rosný bod (°C)', divider: false, highlight: true,
            lastInSection: true}
        ],

        ['humidity-title', {display: 'Vlhkost', divider: true, icon: "humidity_icon_2.png"}],
        ['humidity-hours', {hoursRow: true}],
        ['relativeHumidity', {display: 'Rel. vlhkost (%)', divider: false, highlight: true,
            warningMin: () => 85, criticalMin: () => 95, greatMax: () => 60,
            firstInSection:true, lastInSection: true}
        ],

        ['precipitation-title', {display: 'Srážky', divider: true, icon: "rain_icon_1.png"}],
        ['precipitation-hours', {hoursRow: true}],
        ['precipitation', {display: 'Déšť (mm)', divider: false, highlight: true,
            warningMin: () => 0.001, criticalMin: () => 3,
            firstInSection: true}
        ],
        ['showfall', {display: 'Sníh (cm)', divider: false, highlight: true,
            warningMin: () => 0.001, criticalMin: () => 3}
        ],
        ['snowDepth', {display: 'Hl. sněhu (cm)', divider: false, highlight: true,
            warningMin: () => 5, criticalMin: () => 15,
            lastInSection: true}
        ],
    ]),
    detailValueRounding: new Map([
        ['visibility', {'displayValue': v => removeLeadingZero(v)}],
        ['aerosol550', {'displayValue': v => removeLeadingZero(v)}],
        ['dust550', {'displayValue': v => removeLeadingZero(v)}],
        ['temperature', {'displayValue': v => roundToNearestInteger(v)}],
        ['feelsLike', {'displayValue': v => roundToNearestInteger(v)}],
        ['dewPoint', {'displayValue': v => roundToNearestInteger(v)}],
        ['windSpeed', {'displayValue': v => roundToNearestInteger(v)}],
        ['windGusts', {'displayValue': v => roundToNearestInteger(v)}],
        ['precipitation', {'displayValue': v => removeLeadingZero(v)}],
        ['showfall', {'displayValue': v => removeLeadingZero(v)}],
    ]),
    chartedDetails: new Map([
        ['visibility', {negative: false, maxValue: 50}],
        ['fog', {negative: false, maxValue: 80}], // leaving it smaller on purpose so that high fig is more prominent
        ['aerosol550', {negative: false, maxValue: 0.3}],
        ['dust550', {negative: false, maxValue: 0.3}],
        ['temperature', {negative: true, maxValue: 12, maxValueMapper: value => getMaxValueForTemperature(value)}], // TODO max value must reflect month of year ...
        ['feelsLike', {negative: true, maxValue: 12}],
        ['dewPoint', {negative: false, maxValue: 12}],
        ['windSpeed', {negative: false, maxValue: 10}],
        ['windGusts', {negative: false, maxValue: 10}],
        ['relativeHumidity', {negative: false, maxValue: 80}], // leaving it smaller on purpose so that high fig is more prominent
        ['precipitation', {negative: false, maxValue: 6}],
        ['showfall', {negative: false, maxValue: 6}],
        ['snowDepth', {negative: false, maxValue: 15, valueMapper: value => logBase(1.3, value)}], // achieving a tighter range for very different values
    ]),
    hideableDetailsWhenEmpty: [
        'seeing',
        'transparency',
        'aerosol550',
        'dust550'
    ],
    expandableDetails: [
        'visibility',
        'fog',
        // TODO include in the received details...
        'aerosol550',
        'dust550',
        'temperature',
        'feelsLike',
        'relativeHumidity',
        'dewPoint',
        'windSpeed',
        'windGusts',
        'windAngle',
        'precipitation',
        'snowDepth',
        'showfall',
    ],
    monthlyTemperatureConfig: new Map([
        [1, { chartMaxValue: 12, warningMax: -3, criticalMax: -7 }],
        [2, { chartMaxValue: 12, warningMax: -3, criticalMax: -7 }],
        [3, { chartMaxValue: 16, warningMax: -3, criticalMax: -7 }],
        [4, { chartMaxValue: 19, warningMax: 0, criticalMax: -4 }],
        [5, { chartMaxValue: 22, warningMax: 3, criticalMax: 1 }],
        [6, { chartMaxValue: 23, warningMax: 8, criticalMax: 4 }],
        [7, { chartMaxValue: 25, warningMax: 12, criticalMax: 8 }],
        [8, { chartMaxValue: 24, warningMax: 12, criticalMax: 8 }],
        [9, { chartMaxValue: 20, warningMax: 6, criticalMax: 2 }],
        [10, { chartMaxValue: 18, warningMax: 2, criticalMax: -2 }],
        [11, { chartMaxValue: 16, warningMax: -3, criticalMax: -7 }],
        [12, { chartMaxValue: 12, warningMax: -3, criticalMax: -7 }]
    ]),
    expandedDetails: [],
    detailSourceSelections: {},

    // settings
    showDetailChartsForSources: loadShowDetailChartsForSources(),
    detailChartHeight: loadDetailChartHeight(),
    preferredDetailSource: loadPreferredDetailSource()
}

function getMaxValueForTemperature() {
    const month = new Date().getMonth() + 1;
    return state.monthlyTemperatureConfig.get(month).chartMaxValue;
}

function getWarningMaxTemperature() {
    const month = new Date().getMonth() + 1;
    return state.monthlyTemperatureConfig.get(month).warningMax;
}

function getCriticalMaxTemperature() {
    const month = new Date().getMonth() + 1;
    return state.monthlyTemperatureConfig.get(month).warningMax;
}

function removeLeadingZero(value) {
    if (value) {
        const str = value.toString();
        if (str.startsWith("0.") && value > 0) {
            return str.toString().slice(1);
        }
    }
    return value;
}

function roundToNearestInteger(value) {
    if (value) {
        // TODO warnings get rounded same as Math.round in the BE so do not handle negatives like this now
        // if (value < 0 && value.toString().includes(".5")) {
        //     return Math.floor(value);
        // }
        return Math.round(value);
    }
    return value;
}

// eslint-disable-next-line no-unused-vars
function roundToNearestHalf(value) {
    if (value) {
        const absValue = Math.abs(value);
        const rounded = Math.round(absValue);
        const diff = absValue - rounded;

        if (Math.abs(diff) >= 0.25 && Math.abs(diff) < 0.75) {
            return Math.sign(value) * (rounded + 0.5 * Math.sign(diff));
        }
        return Math.sign(value) * rounded;
    }
    return value;
}

function logBase(base, number) {
    return Math.log(number) / Math.log(base);
}

function loadShowDetailChartsForSources() {
    const value = localStorage.getItem("showDetailChartsForSources");
    if (value === null || value === undefined) {
        return true; // do show by default
    }
    const resolved = value === true || value === 'true';
    // console.log("loadShowDetailChartsForSources = " + resolved)
    return resolved;
}

function loadDetailChartHeight() {
    const value = localStorage.getItem("detailChartHeight");
    if (value === null || value === undefined) {
        return CHART_HEIGHT.MEDIUM.name;
    }
    return value;
}

function loadPreferredDetailSource() {
    const value = localStorage.getItem("preferredDetailSource");
    if (value === null || value === undefined) {
        return "METEOBLUE_WEB";
    }
    return value;
}


const getters = {

    getDetails: (state) => {
        return state.details;
    },

    getChartedDetails: (state) => {
        return state.chartedDetails;
    },

    getExpandedDetails: (state) => {
        return state.expandedDetails;
    },

    getExpandableDetails: (state) => {
        return state.expandableDetails;
    },

    isShowDetailChartsForSources: (state) => {
        return state.showDetailChartsForSources;
    },

    getDetailChartHeight: (state) => {
        return state.detailChartHeight;
    },

    getDetailChartHeightPx: (state) => {
        let name = state.detailChartHeight;
        let option = CHART_HEIGHT.findOption(name);
        if (option) {
            return option.height;
        }
        return CHART_HEIGHT.MEDIUM.height;
    },

    getDetailChartRowHeightPx: (state) => {
        let name = state.detailChartHeight;
        let option = CHART_HEIGHT.findOption(name);
        if (option) {
            return option.rowHeight;
        }
        return CHART_HEIGHT.MEDIUM.rowHeight;
    },

    getPreferredDetailSource: (state) => {
        return state.preferredDetailSource;
    },

    getDisplayedSourcesAlphabetically: (state, getters) => {
        let forbiddenSources1 = getters.getForbiddenSources;
        return state.displayedSources
            .filter(d => !forbiddenSources1.includes(d));
    },

    getDetailFields: (state, getters) => {
        return Array.from(state.details.keys())
            .filter(k => {
                if (state.hideableDetailsWhenEmpty.includes(k)) {
                    return getters.existForecastDetail(k);
                } else {
                    return k;
                }
            })
            .map(key => {
                // eslint-disable-next-line no-unused-vars
                const lastInSection = state.details.get(key).lastInSection;
                // eslint-disable-next-line no-unused-vars
                const firstInSection = state.details.get(key).firstInSection;

                let keySources = Sources.getSourceCodes() // include the sole key and then those source code for which we have data ...
                    .filter(sourceCode => sourceCode && state.expandableDetails.includes(key) && state.expandedDetails.includes(key))
                    .filter(sourceCode => getters.existForecastDetail(key, sourceCode))
                    .map(sourceCode => key + "." + sourceCode);

                if (keySources.length > 0) {
                    let emptyRowKey = key + "." + 'empty-row';
                    // if (firstInSection && lastInSection) {
                    //     return [key, ...keySources];
                    // } else if (lastInSection) {
                    //     return [emptyRowKey, key, ...keySources];
                    // } else if (firstInSection) {
                    //     return [key, ...keySources, emptyRowKey];
                    // } else {
                    //     return [emptyRowKey, key, ...keySources, emptyRowKey];
                    // }

                    return [key, ...keySources, emptyRowKey];

                    // if (lastInSection) {
                    //     return [key, ...keySources];
                    // } else {
                    //     return [key, ...keySources, emptyRowKey];
                    // }

                } else {
                    return [key]
                }
            })
            .flat();
    },

    getLastForecastDetailSource:  (state, getters) => (detailField) => { // if we have multiple sources for a detail, which ones is the last?
        const map = new Map();
        getters.getDetailFields.forEach((value) => {
            const detailKey = ForecastDetailsUtils.getDetailKey(value);
            const detailSourceCode = ForecastDetailsUtils.getDetailSourceCode(value);
            if (detailSourceCode !== null) {
                map.set(detailKey, detailSourceCode);
            }
        })
        const detailKey = ForecastDetailsUtils.getDetailKey(detailField);
        return map.get(detailKey);
    },

    getForecastDetailValues: (state, getters, rootState, rootGetters) => (detailField) => {
        const values = rootGetters["forecastsStore/hours"]
            .map(hour => {
                const forecast = rootGetters["forecastsStore/getDetailedForecast"](hour);
                if (forecast) {
                    const detailKey = ForecastDetailsUtils.getDetailKey(detailField);
                    const hourly = rootGetters["forecastsStore/getHourForecast"](hour, forecast, null);
                    if (hourly) {
                        const value = hourly[detailKey];
                        return value;
                    }
                }
                return null;
            })
            .filter(v => v !== null)
        // console.log(values)
        return values;
    },

    // TODO similar method for showing if there is a value for that field + source or not ...
    existForecastDetail: (state, getters, rootState, rootGetters) => (name, sourceCode) => {
        const hours = rootGetters["forecastsStore/hours"];
        const minValues = 8; // we need at least 8 values to show the detail - to avoid almost empty rows
        let valueCount = 0;
        for (let hour of hours) {
            let forecast = rootGetters["forecastsStore/getDetailedForecast"](hour);
            if (forecast) {
                let hourly = rootGetters["forecastsStore/getHourForecast"](hour, forecast, sourceCode);
                if (hourly) {
                    const value = hourly[name];
                    if (value || value === 0) {
                        valueCount++;
                        if (valueCount === minValues) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    },

    getDetailValueRounding: (state) => (detailField) => {
        const detailKey = ForecastDetailsUtils.getDetailKey(detailField);
        if (detailKey) {
            return state.detailValueRounding.get(detailKey);
        }
        return null;
    },

}


const mutations = {

    doToggleExpandableDetail: (state, detailKey) => {
        if (!state.expandedDetails.includes(detailKey)) {
            state.expandedDetails.push(detailKey);
        } else {
            // remove the element ...
            let index = state.expandedDetails.indexOf(detailKey);
            if (index !== -1) {
                state.expandedDetails.splice(index, 1);
            }
        }
    },

    setShowDetailChartsForSources: (state, value) => {
        state.showDetailChartsForSources = value;
        localStorage.setItem("showDetailChartsForSources", value)
    },

    setDetailChartHeight: (state, value) => {
        state.detailChartHeight = value;
        localStorage.setItem("detailChartHeight", value)
    },

    setPreferredDetailSource: (state, value) => {
        state.preferredDetailSource = value;
        localStorage.setItem("preferredDetailSource", value)
    }

}

const actions = {

    // eslint-disable-next-line no-unused-vars
    toggleExpandableDetail({commit, dispatch}, detailKey) {
        commit('doToggleExpandableDetail', detailKey);
    },

    changeShowDetailChartsForSources({commit}, value) {
        commit('setShowDetailChartsForSources', value);
    },

    changeDetailChartHeight({commit}, heightValue) {
        commit('setDetailChartHeight', heightValue);
    },

    changePreferredDetailSource({commit}, source) {
        commit('setPreferredDetailSource', source);
    }

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}



export class ForecastDetailsUtils {

    static getDetailKey(detail) {
        return detail.split('.')[0];
    }

    static getDetailSourceCode(detail) {
        if (ForecastDetailsUtils.isEmptyRow(detail)) {
            return null;
        }
        let result = detail.split('.');
        if (result.length > 1) {
            return result[1];
        }
        return null;
    }

    static isEmptyRow(detail) {
        return detail.includes("empty-row");
    }

    static toDetailKey(detail, sourceCode) {
        if (sourceCode) {
            return detail + "." + sourceCode;
        } else {
            return detail;
        }
    }

}
