<template>

    <div style="display: flex; flex-direction: column; align-items: center">
        <div v-if="type==='seeing' && includeValue === true" style="font-size: 10px; margin-bottom: 5px">
            {{getSeeingValue()}}
        </div>
        <div v-if="type==='seeing'" :style="getSymbolStyle()"></div>

        <div v-if="type==='transparency' && includeValue === true" style="font-size: 10px; margin-bottom: 5px">
            {{getTransparencyValue()}}
        </div>
        <div v-if="type==='transparency'">
            <div v-for="idx in getLineCount" v-bind:key="idx" :style="getSymbolStyle()"></div>
        </div>
    </div>

</template>

<script>


export default {
    name: "SkyQuality",
    props: {
        type: null, // seeing/transparency/seeing_range/transparency_range
        includeValue: null,
        // eslint-disable-next-line vue/require-prop-type-constructor
        value: 0,
    },
    data() {
        return {
            seeingSymbol: [
                {size: 4, bckg: '#2171bd', value: '0.5'},
                {size: 5.5, bckg: '#3480c5', value: '0.75'},
                {size: 7, bckg: '#5394d2', value: '1'},
                {size: 9, bckg: '#7fb2e0', value: '1.25'},
                {size: 11, bckg: '#8fb9de', value: '1.5'},
                {size: 13, bckg: '#e2e3e8', value: '2'},
                {size: 15, bckg: '#f1f1f1', value: '2.5'},
                {size: 17, bckg: '#f6f6f8', value: '>2.5'},
            ],
            transparencySymbol: [
                {size: 1, bckg: '#2171bd', value: '0.3'},
                {size: 2, bckg: '#3480c5', value: '0.4'},
                {size: 3, bckg: '#5394d2', value: '0.5'},
                {size: 4, bckg: '#7fb2e0', value: '0.6'},
                {size: 5, bckg: '#bbd9ef', value: '0.7'},
                {size: 6, bckg: '#cfd9e7', value: '0.85'},
                {size: 7, bckg: '#d3d3d3', value: '1'},
                {size: 8, bckg: '#dadada', value: '>1'},
            ],
        }
    },
    computed: {
        getLineCount() {
            if (this.value > 0) {
                return this.transparencySymbol[this.value - 1].size;
            } else {
                return 0;
            }
        }
    },
    methods: {
        getSymbolStyle() {
            // console.log("getSymbolStyle = " + this.value);
            if (this.value > 0 && this.value <= 8) {
                if (this.type === "seeing") {
                    const symbol = this.seeingSymbol[this.value - 1];
                    return `font-size: 10px; width: ${symbol.size}px; height: ${symbol.size}px; border-radius: 50%; background-color: ${symbol.bckg}; border: 1px solid #939393`
                } else if (this.type === "transparency") {
                    const symbol = this.transparencySymbol[this.value - 1];
                    return `width: 12px; height: 1.5px; margin-top: 1px; background-color: ${symbol.bckg}`
                }
            }
            return '';
        },
        getSeeingValue() {
            return this.seeingSymbol[this.value - 1].value;
        },
        getTransparencyValue() {
            return this.transparencySymbol[this.value - 1].value;
        }
    },
    components: {
    }
}

</script>

<style scoped>

</style>
