import {ApiClient} from "@/api/calculations/api-client";
import {Utils} from "@/utils/Utils";

const state = {
    commentaryUuid: null,
    fetchType: null,
    commentary: null,
    commentaryParts: [],
    loadingCommentary: true,
    loadingCommentaryParts: false,
    eventSource: null,
}


const getters = {

    getCommentaryUuid: (state) => {
        return state.commentaryUuid;
    },

    getFetchType: (state) => {
        return state.fetchType;
    },

    getCommentary: (state) => {
        return state.commentary;
    },

    getCommentaryParts: (state) => {
        return state.commentaryParts;
    },

    isLoadingCommentary: (state) => {
        return state.loadingCommentary;
    },

    isLoadingCommentaryParts: (state) => {
        return state.loadingCommentaryParts;
    },

    getEventSource: (state) => {
        return state.eventSource;
    },

}


const mutations = {

    setCommentaryUuid: (state, value) => {
        state.commentaryUuid = value;
    },

    setFetchType: (state, value) => {
        state.fetchType = value;
    },

    setCommentary: (state, value) => {
        state.commentary = value;
    },

    setCommentaryParts: (state, value) => {
        state.commentaryParts = value
    },

    resetCommentaryParts: (state) => {
        // ensures reactivity
        state.commentaryParts.splice(0, state.commentaryParts.length);
    },

    addCommentaryPart: (state, value) => {
        state.commentaryParts.push(value);
    },

    setLoadingCommentary: (state, value) => {
        state.loadingCommentary = value;
    },

    setLoadingCommentaryParts: (state, value) => {
        state.loadingCommentaryParts = value;
    },

    setEventSource: (state, value) => {
        state.eventSource = value;
    }
}


const actions = {

    // eslint-disable-next-line no-unused-vars
    updateCommentaryUuid({ commit, getters, dispatch }, {uuid, fetchType}) {
        console.log("Received commentary uuid = " + uuid)
        commit('setFetchType', 'fetchType');
        commit('setCommentaryUuid', uuid);

        commit('setCommentary', null); // clear the commentary
        commit('resetCommentaryParts'); // clear the commentary parts

        dispatch('fetchForecastsCommentary', { uuid, fetchType });
    },

    // eslint-disable-next-line no-unused-vars
    fetchForecastsCommentary({commit, getters},  { uuid, fetchType }) {
        if (!Utils.isMe()) {
            return;
        }
        if (uuid) {
            console.log("fetching forecasts commentary ...")

            commit('setLoadingCommentary', true);

            if ('FULL' === fetchType) {
                ApiClient.fetchForecastsCommentary(uuid)
                    .then(commentary => {
                            // TODO we must check if the commentary is for the last uuid we wanted !!! (in case of multiple fast requests)
                            if (commentary) {
                                console.log("Received forecasts commentary: " + commentary)
                                commit('setCommentary', commentary);
                            } else {
                                console.log("Failed to receive forecasts commentary")
                                commit('setCommentary', null);
                            }
                            commit('setLoadingCommentary', false);
                        }
                    )
                    .catch(error => {
                        console.error("Failed to fetch forecasts commentary: " + error)
                        commit('setCommentary', null);
                        commit('setLoadingCommentary', false);
                    });
            } else if ('STREAMED' === fetchType) {
                const eventSource = ApiClient.streamForecastsCommentary(uuid,
                    (commentaryPart) => {
                        commit('setLoadingCommentary', false);
                        commit('setLoadingCommentaryParts', true);
                        // TODO set that we are still loading rest
                        if (commentaryPart) {
                            console.log("Received commentary part: " + commentaryPart)
                            commit('addCommentaryPart', commentaryPart);
                        } else {
                            console.log("Failed to receive commentary part")
                        }
                    }, () => {
                        console.log("Finished fetching commentary parts")
                        commit('setLoadingCommentary', false);
                        commit('setLoadingCommentaryParts', false);
                    }
                );
                commit('setEventSource', eventSource);
            } else {
                console.error("Unknown fetch type: " + fetchType);
            }

        } else {
            commit('setLoadingCommentary', false);
            console.error("Cannot fetch forecasts commentary - uuid is null!");
        }
    },

    stopCommentaryStream({commit, getters}) {
        if (getters.getEventSource) {
            getters.getEventSource.close();
            commit('setEventSource', null);
            console.log("Stopped commentary stream");
        }
    }

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}


