<template>
    <div v-if="showCommentarySection" class="commentary"
         :class="getCommentary !== null ? 'commentary-loaded' : 'commentary-loading'">
        <div v-if="isLoadingCommentary"
            class="d-flex align-items-center">
            <b-spinner type="border" label="Loading..."
                       style="color: rgba(66,188,233, 1); width: 15px; height: 15px; margin-right: 10px">
            </b-spinner>
            <span>Za chvíli tu bude komentář k předpovědím oblačnosti ...</span>
        </div>
        <div v-else-if="getCommentaryText" @click="showMore = !showMore" style="cursor: pointer">
            <div :class="{'text-container': true, 'expanded': showMore}">
                <span class="material-symbols-outlined icon">search_insights</span>
                <span v-html="getCommentaryText"></span>
            </div>
            <div class="d-flex align-items-center justify-content-center" style="margin-top: 5px">
                <span v-if="!showMore" class="material-symbols-outlined expand-detail-icon notranslate">
                    expand_circle_down
                </span>
                <span v-else class="material-symbols-outlined expand-detail-icon notranslate">
                    expand_circle_up
                </span>
            </div>
        </div>
        <div v-else-if="getCommentaryPartsText" @click="showMore = !showMore" style="cursor: pointer">
            <div :class="{'text-container': true, 'expanded': showMore}">
                <span class="material-symbols-outlined icon">search_insights</span>
                <transition-group name="fade-in-commentary-part" tag="span" appear>
                    <span v-for="(text, index) in getCommentaryPartsText" :key="index"
                          v-html="text" class="fade-in-commentary-part">
                    </span>
                </transition-group>
                <DotSpinner v-if="isLoadingCommentaryParts" style="margin-left: 10px"></DotSpinner>
            </div>
            <div class="d-flex align-items-center justify-content-center" style="margin-top: 5px">
                <span v-if="!showMore" class="material-symbols-outlined expand-detail-icon notranslate">
                    expand_circle_down
                </span>
                <span v-else class="material-symbols-outlined expand-detail-icon notranslate">
                    expand_circle_up
                </span>
            </div>
        </div>

    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {Utils} from "@/utils/Utils";
import {BSpinner} from "bootstrap-vue";
import {Sources} from "@/config/sources";
import DotSpinner from "@/components/reusables/DotSpinner.vue";

export default {
    name: "ForecastCommentary",
    data() {
        return {
            showMore: false
        }
    },
    computed: {
        Utils() {
            return Utils
        },
        ...mapGetters('forecastCommentariesStore', ['getCommentary', 'getCommentaryParts', 'isLoadingCommentary', 'isLoadingCommentaryParts']),
        ...mapGetters('forecastsStore', ['getLocation', 'isDisplayedWholeCountry', 'isForeignLocation']),
        getCommentaryText() {
            if (this.getCommentary) {
                let text = this.getCommentary.text;
                let result = text;
                // eslint-disable-next-line no-unused-vars
                for (const [key, value] of Object.entries(Sources.sources)) {
                    const regex = new RegExp(value, 'g');
                    result = result.replace(regex, `<span class="commentary-model-name">${value}</span>`);
                }
                return result;
            } else {
                return null;
            }
        },
        getCommentaryPartsText() {
            let resultParts = this.getCommentaryParts
                .map(part => part.text)
                .map(text => {
                    let result = text;
                    // eslint-disable-next-line no-unused-vars
                    for (const [key, value] of Object.entries(Sources.sources)) {
                        const regex = new RegExp(value, 'g');
                        result = result.replace(regex, `<span class="commentary-model-name">${value}</span>`);
                    }
                    // return result + '&nbsp;'; // Add space to separate parts
                    return result; // Add space to separate parts
                })
            return resultParts;
        },
        showCommentarySection() {
            return Utils.isMe() && this.getLocation && !this.isDisplayedWholeCountry && !this.isForeignLocation
                && (this.isLoadingCommentary || this.getCommentary || this.getCommentaryParts)
        }
    },
    methods: {
        ...mapActions('forecastCommentariesStore', []),
    },
    components: {
        DotSpinner,
        BSpinner
    }
}
</script>

<style scoped>

.commentary {
    background-color: #f8f8f8;
    border-radius: 10px;
    padding: 10px 10px 5px;
    margin-bottom: 12px;
    text-align: justify;
    position: relative;
    user-select: none;
}

.fade-in-commentary-part-enter-active, .fade-in-commentary-part-leave-active {
    transition: opacity 1.1s ease-in;
}
.fade-in-commentary-part-enter, .fade-in-commentary-part-leave-to /* .fade-in-commentary-part-leave-active in <2.1.8 */ {
    opacity: 0;
}

.commentary-loaded {
    padding: 10px 10px 5px;
}

.commentary-loading {
    padding: 10px;
}

.text-container {
    overflow: hidden;
    max-height: 3em; /* Adjust based on line height */
    line-height: 1.5em; /* Adjust based on font size */
    text-overflow: ellipsis;
    //white-space: nowrap; /* Causes the text to be on single line but also achieves the three dots to appear ... */
    display: block;
}

.text-container.expanded {
    max-height: none;
    white-space: normal;
}

@media (max-width: 699px) {
    .text-container.expanded {
        /* Adjust based on line height - the multiples of which determine the number of lines shown ...*/
        min-height: 13.5em;
    }
}
@media (min-width: 700px) {
    .text-container.expanded {
        /* Adjust based on line height - the multiples of which determine the number of lines shown ...*/
        min-height: 7.5em;
    }
}

.icon {
    font-size: 20px;
    color: rgb(8, 108, 210);
    vertical-align: middle; /* Aligns the icon with the middle of the text */
    margin-right: 5px;
}

@media (max-width: 699px) {
    .commentary {
        font-size: 13px;
        margin-left: 10px;
        margin-right: 10px
    }
}

@media (min-width: 700px) {
    .commentary {
        font-size: 14px;
        margin-left: 30px;
        margin-right: 30px
    }
}

</style>

<!--global styles ensuring computed html renders with styles-->
<style>
.commentary-model-name {
    font-weight: 600;
    color: #414141;
}
</style>
